<template>
  <div class="exhibition-wrap">
    <img class="part1-title" src="@/assets/img/exhibition/part1-title.png" alt="">
    <div class="preview"></div>
    <div class="works-container">
      <div class="origin-btn-wrap">
        <div :class="['btn', originId === 1 ? 'active' : '']" @click="handleOriginChange(1)">特邀作品</div>
        <div :class="['btn', originId === 2 ? 'active' : '']" @click="handleOriginChange(2)">日韩作品</div>
        <div :class="['btn', originId === 3 ? 'active' : '']" @click="handleOriginChange(3)">大赛作品</div>
      </div>
      <div class="type-btn-wrap">
        <div :class="['btn', typeId === 1 ? 'active' : '']" @click="handleTypeChange(1)">平面类</div>
        <div :class="['btn', typeId === 2 ? 'active' : '']" @click="handleTypeChange(2)">动画类</div>
      </div>
      <div class="wroks-wrap">
        <div class="work-item" v-for="item in list.workList" :key="item.headImg" @click="handleGoDetail(item.id)">
          <div class="img">
            <img :src="item.headImg" alt="">
          </div>
          <p>{{ item.name }}</p>
          <p>{{ item.author }}</p>
        </div>
      </div>
      <div class="pagination">
        <el-pagination v-if="pageInfo.total > 0" @current-change="value => pageInfo.pageNum = value"
          :currentPage="pageInfo.pageNum" background :page-size="pageInfo.pageSize" layout=" prev, pager, next"
          :total="pageInfo.total">
        </el-pagination>
      </div>
    </div>
    <img class="part2-title" src="@/assets/img/exhibition/part2-title.png" alt="">
    <div class="news-container">
      <div class="news-wrap">
        <div class="news-item" v-for="item in news.newsList" :key="item.cover" @click="handleNewsClick(item.link)">
          <div class="cover" :style="{
            'background-image': `url(${item.cover})`
          }"></div>
          <div class="p-wrap">
            <p class="create-time">{{ item.createTime }}</p>
            <p class="title">{{ item.title }}干撒回公司科技干撒回公司科技干撒回公司科技干撒回公司科技干撒回公司科技</p>
            <p class="desc">{{ item.description
              }}干撒回公司科技干撒回公司科技干撒回公司科技干撒回公司科技干撒回公司科技干撒回公司科技干撒回公司科技干撒回公司科技干撒回公司科技干撒回公司科技干撒回公司科技干撒回公司科技干撒回公司科技干撒回公司科技干撒回公司科技干撒回公司科技干撒回公司科技
            </p>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination v-if="newsPageInfo.total > 0" @current-change="value => newsPageInfo.pageNum = value"
          :currentPage="newsPageInfo.pageNum" background :page-size="newsPageInfo.pageSize" layout=" prev, pager, next"
          :total="newsPageInfo.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { entryList, listByPage } from '../utils/apis/exhibition';

export default {
  name: "exhibition",
  setup() {
    const router = useRouter()
    const originId = ref(1)
    const typeId = ref(1)
    const pageInfo = reactive({
      pageNum: 1,
      pageSize: 8,
      total: 0
    })
    const list = reactive({
      workList: [],
    });
    const newsPageInfo = reactive({
      pageNum: 1,
      pageSize: 3,
      total: 0
    })
    const news = reactive({
      newsList: []
    })
    const handleOriginChange = origin => {
      if (origin === originId.value) return;
      originId.value = origin
    }
    const handleTypeChange = type => {
      if (type === typeId.value) return;
      typeId.value = type
    }
    const handleGoDetail = id => {
      router.push(`/exhibitionWorkDetail?id=${id}&cate=${typeId.value}`)
    }
    const handleNewsClick = link => {
      window.open(link, '_blank')
    }
    watchEffect(async () => {
      let params = {
        category: typeId.value,
        type: originId.value,
        text: '',
        pageNum: pageInfo.pageNum,
        pageSize: pageInfo.pageSize
      };
      const res = await entryList(params)
      let data = res.data
      if (data.code === 200) {
        list.workList = data.data.list
        pageInfo.pageNum = data.data.pageNum
        pageInfo.total = data.data.total
      }
    })
    watchEffect(async () => {
      const res = await listByPage(newsPageInfo.pageNum, newsPageInfo.pageSize)
      let data = res.data
      if (data.code === 200) {
        news.newsList = data.data.list
        newsPageInfo.pageNum = data.data.pageNum
        newsPageInfo.total = data.data.total
      }
    })
    return {
      originId,
      typeId,
      pageInfo,
      list,
      newsPageInfo,
      news,
      handleOriginChange,
      handleTypeChange,
      handleGoDetail,
      handleNewsClick
    };
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "FZMHJW";
  src: url("../assets/fonts/FZMHJW.TTF") format("truetype");
}

//一般的flex布局
.ordinary-flex-layout {
  display: flex;
  justify-content: space-between;
}

// 多行省略号mixin：@line为行数
.multi-line-ellipsis(@line) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: @line;
  overflow: hidden;
}

.exhibition-wrap {
  padding-top: 111px;
  padding-bottom: 121px;
  box-sizing: border-box;
  min-height: 1000px;
  background-image: url("../assets/img/detailPic/bg.png");
  background-position: center;
  position: relative;
  user-select: none;

  &>img,
  &>div {
    display: block;
    margin: 0 auto;
  }

  .part1-title {
    width: 432px;
    height: 114px;
    margin-bottom: 55px;
  }

  .preview {
    width: 1200px;
    height: 675px;
    background: #dbe6f4;
    border-radius: 8px;
    margin-bottom: 111px;
  }

  .works-container {
    width: 1364px;
    height: 958px;
    background: rgba(255, 255, 255, 0.10);
    border-radius: 4px;
    box-shadow: -4.23px 9.06px 50px 10px rgba(25, 51, 95, 0.20), -120px 0px 250px 120px rgba(255, 255, 255, 0.15) inset;
    margin-bottom: 110px;
    padding: 70px 46px 64px 45px;
    box-sizing: border-box;

    .origin-btn-wrap {
      margin: 0 auto;
      width: 1009px;
      .ordinary-flex-layout();
      margin-bottom: 30px;

      .btn {
        width: 269px;
        height: 90px;
        border-radius: 35px;
        background-image: url('../assets/img/exhibition/btn-bg.png');
        background-position: center;
        background-size: contain;
        font-size: 46px;
        font-family: FZMHJW;
        font-weight: 400;
        text-align: center;
        color: #001c4d;
        line-height: 75px;
        cursor: pointer;
      }

      .active {
        color: #ffffff;
      }
    }

    .type-btn-wrap {
      width: 142px;
      height: 30px;
      background: #ffffff;
      border-radius: 4px;
      margin-bottom: 20px;
      padding: 2px;
      box-sizing: border-box;
      .ordinary-flex-layout();

      .btn {
        width: 70px;
        height: 26px;
        background: #ffffff;
        border-radius: 4px;
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN-Normal;
        font-weight: normal;
        text-align: center;
        color: #234471;
        line-height: 26px;
        cursor: pointer;
      }

      .active {
        background: #234471;
        color: #dbe6f4;
      }
    }

    .wroks-wrap {
      width: 1274px;
      height: 576px;
      .ordinary-flex-layout();
      flex-wrap: wrap;

      .work-item {
        height: 268px;
        width: 303px;
        margin-right: 19px;
        margin-bottom: 40px;

        .img {
          width: 303px;
          height: 209px;
          border-radius: 8px;
          margin-bottom: 15px;
          overflow: hidden;
          &>img {
            width: 100%;
            /* 或者其他固定宽度 */
            height: auto;
            object-fit: cover;
          }
        }

        &>p {
          font-size: 16px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          font-weight: 400;
          text-align: left;
          margin-bottom: 8px;
          color: #e7f1ff;
        }
      }

      .work-item:nth-child(4n) {
        margin-right: 0;
      }

      .work-item:last-child {
        margin-right: auto;
      }
    }


  }

  .part2-title {
    width: 242px;
    height: 114px;
    margin-bottom: 56px;
  }

  .news-container {
    margin: 0 auto;

    .news-wrap {
      margin: 0 auto;
      width: 1110px;
      height: 556px;
      .ordinary-flex-layout();
      margin-bottom: 60px;

      .news-item {
        width: 353px;
        height: 566px;
        margin-right: 24px;
        background: #f3f7fd;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .cover {
          width: 353px;
          height: 227px;
          background-size: cover;
          background-position: center;
        }

        .p-wrap {
          height: 339px;
          width: 353px;
          padding: 28px 25px 73px 24px;
          box-sizing: border-box;

          .create-time {
            font-size: 14px;
            margin-bottom: 28px;
          }

          .title {
            font-size: 24px;
            margin-bottom: 31px;
            .multi-line-ellipsis(2)
          }

          .desc {
            font-size: 15px;
            .multi-line-ellipsis(5)
          }
        }
      }

      .news-item:nth-child(3n) {
        margin-right: 0;
      }

      .news-item:last-child {
        margin-right: auto;
      }
    }
  }

  .pagination {
    text-align: center;
    margin-top: 25px;
    padding-bottom: 85px;
  }

  .pagination /deep/ .el-pager li {
    width: 38px;
    height: 38px;
    line-height: 38px;
    border: 1px solid #dde5f0;
    color: #dde5f0;
    border-radius: 4px;
    background: transparent;
  }

  .pagination /deep/ .el-pager li.active {
    background: #abd5fb;
    color: #001c4d;
  }

  .pagination /deep/ .el-pagination .btn-next,
  .pagination /deep/ .el-pagination .btn-prev {
    width: 38px;
    height: 38px;
    border: 1px solid #dde5f0;
    border-radius: 4px;
    background: transparent;
  }
}
</style>