import request from "../request";

//展映作品列表
export function entryList(params) {
  return request({
    url: `/display/entryList`,
    method: "GET",
    params
  });
}

//作品详情
export function entryDetail(id) {
  return request({
    url: `/display/entryDetail?id=${id}`,
    method: "GET",
  });
}

//获取展映咨询
export function listByPage(pageNum, pageSize) {
  return request({
    url: `/display/listByPage?pageNum=${pageNum}&pageSize=${pageSize}`,
    method: "GET",
  });
}
